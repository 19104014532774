.header {
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 58px;
  background: linear-gradient(to right, #221b9b, #00235e);
  backdrop-filter: blur(14px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_logo {
    width: 77px;
    height: 20px;
  }
  &_center {
    display: none;
  }
  &_right {
    display: flex;
    align-items: center;
    &_language {
      width: 32px;
      height: 32px;
      border: 2px solid #3052f4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    &_connect {
      box-sizing: border-box;
      height: 32px;
      border-radius: 20px;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 14px;
      margin: 0 18px 0 23px;
      padding: 0 20px;
    }
    &_connected {
      width: 85px;
      height: 34px;
      margin: 0 16px 0 23px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      justify-content: space-between;
      padding: 0 17px;
      &_wallet {
        width: 20px;
        height: 20px;
      }
      &_downarrow {
        margin-top: 3px;
        width: 16px;
        height: 16px;
      }
    }
    &_menu {
      width: 31px;
      height: 29px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &_Modal {
    border: 1px solid #6f7ece;
    background: #323e7e;
    border-radius: 18px;
    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 36px;
      span {
        font-size: 14px;
        color: #ffffff;
      }
      img {
        width: 15px;
        height: 15px;
      }
    }
    &_name {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      padding-bottom: 12px;
    }
    &_address {
      height: 44px;
      border-radius: 12px;
      background: #eff2ff;
      color: #3e59c8;
      box-sizing: border-box;
      padding: 0 17px;
      line-height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 29px;
      &_span2 {
        display: none;
      }
      img {
        width: 12px;
        height: 12px;
      }
    }
    &_btn {
      display: block;
      margin: 0 auto 9px;
      width: 208px;
      height: 32px;
    }
    &_fiboscan {
      font-size: 12px;
      color: #dce1fc;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 10px;
        width: 10px;
        height: 10px;
      }
    }
  }
  &_Drawer {
    padding-top: 26px;

    &_logo {
      width: 85px;
      height: 22px;
    }
    &_closeIcon {
      width: 18px;
      height: 18px;
    }
    .ant-drawer-header {
      padding-left: 0;
      padding-right: 0;
      margin: 0 16px;
    }
    .ant-drawer-body {
      padding: 0;
    }
    &_Menu {
      margin-top: 10px;
      .ant-menu-item-selected {
        background: linear-gradient(
          90deg,
          #060b7c 0%,
          rgba(26, 30, 43, 0) 138%
        );
      }
      &_img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        margin-right: 2px;
      }
      &_game {
        width: 21px;
        height: 16px;
        margin-right: 2px;
      }
      &_country {
        width: 16px;
        height: 16px;
        margin-left: 3px;
        margin-right: 4px;
      }
    }
  }
}
@media screen and (min-width: 780px) {
  .header {
    &_logo {
      cursor: pointer;
    }
    padding: 0 50px;
    &_center {
      display: flex;
      align-items: center;
      position: fixed;
      top: 0;
      left: 150px;
      height: 100%;
      min-width: 310px;
      &_Menu {
        width: 100%;
        background-color: transparent;
        color: #fff !important;

        .ant-menu-item-selected {
          background: transparent;
        }
      }
    }
    &_right {
      &_menu {
        display: none;
      }
    }
    &_Modal {
      &_title {
        img {
          cursor: pointer;
        }
      }
      &_address {
        img {
          cursor: pointer;
        }
        &_span1 {
          display: none;
        }
        &_span2 {
          display: inline-block;
        }
      }
      &_fiboscan {
        span {
          cursor: pointer;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
 
}
