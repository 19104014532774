.foot {
  box-sizing: border-box;
  padding: 34px 19px 24px;
  background: linear-gradient(to bottom, #0f003b, #15135b);
  &_logo {
    padding-bottom: 59px;
    img {
      width: 125px;
      height: 32px;
    }
  }
  &_link {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 66px;
    &_left {
      font-size: 14px;
      color: #ffffff;
      div {
        padding-bottom: 15px;
        &:first-child {
          font-weight: bold;
          font-size: 16px;
        }
        &:last-child {
          padding: 0;
          &:hover{
            color: #5E56EE;
          }
        }
      }
    }
    &_right {
      width: 120px;
      height: 29px;
      border-radius: 31px;
      background: #211c55;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 12px 0 14px;
      &:hover{
        background-color: #403C6D;
      }
      img {
        width: 16px;
        height: 16px;
        &:last-child {
          width: 11px;
          height: 6px;
        }
      }
      &_item {
        text-align: center;
      }
    }
  }
  &_href {
    display: flex;
    div {
      width: 38px;
      height: 38px;
      background: rgba(124, 124, 124, 0.44);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 23px;
      border-radius: 50%;
      &:last-child {
        margin-right: 0;
      }
    }
    div:hover {
      background: #5E56EE;
    }
    &_T {
      width: 17px;
      height: 14px;
    }
    &_D {
      width: 20px;
      height: 14px;
    }
    &_X {
      width: 21px;
      height: 17px;
    }
    &_F {
      width: 12px;
      height: 22px;
    }
  }
}
@media screen and (min-width:780px) {
  .foot {
    &_link {
      div{
        cursor: pointer;
      }
    }
    &_href{
      div{
        cursor: pointer;
      }
    }
  }
}
